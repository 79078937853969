import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const IndustryHeroBg = () => {
  return (
    <StaticImage
      src="../../images/industry-hero-bg.png"
      placeholder="blurred"
      alt="AES"
    />
  )
}

export default IndustryHeroBg

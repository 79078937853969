import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Container, Section } from "../components/SectionTags"
import styled from "styled-components"
import Slider from "react-slick"
import {
  BreadCrumb,
  Background,
  Arrow,
  Badge,
  WriterWrap,
  Avatar,
  Writer,
  MarkdownContent,
  SectionPageTitle,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import IndustryHeroBg from "../components/Image/IndustryHeroBg"
import ItemHover from "../components/ItemHover"
import DotIcon from "../components/Icons/DotIcon"
import ArrowIcon from "../components/Icons/ArrowIcon"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px -15px 0 -15px;
  ${BreakpointUp.xl`
    margin: 60px -15px 0 -15px;
  `}
`
const GridItem = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  display: ${(props) => props.display};
  ${BreakpointUp.md`
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.lg`
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  `}
`
const ArticleList = styled.div`
  
`
const ArticleCard = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom:30px;
  .gatsby-image-wrapper {
    /* height: 25vw;
    max-height: 270px;*/
    width: 100%; 
    border-radius: 8px;
  }
  &:hover {
    position: relative;
    z-index: 10;
    width: calc(100% + 80px);
    height: calc(100% + 10px);
    margin-top: -15px;
    margin-left: -40px;
    padding: 40px 40px 0px;
    background: white;
    box-shadow: 0 10px 65px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
  }
`
const ArticleHeader = styled.div`
  position: relative;
  margin-bottom:20px;
`
const ArticleBody = styled.div`
  .content{
    font-style:italic;
    margin-bottom:20px;
    color:#333;
    font-size: 16px;
    line-height: 28px;
    @media (min-width: 1200px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
`
const ArticleTitle = styled.h2`  
  margin:0 0 10px; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 20px;
  line-height: 30px;
  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 32px;
  }
  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 34px;
  }  
`
const ArticleCaption = styled.div`
  color: #fff;
  font-size: 14px;
  line-height: 28px;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  position:absolute;
  bottom: 10px;
  left: 20px;
`
const ArticleLink = styled.div`  
  font-size: 16px;
  line-height: 22px;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 700;
  color: #FAB702;
  cursor: pointer;
  svg {
    vertical-align: middle;
    margin-left: 5px;
    transition: transform ease .2s;
  }
  &:hover {
    transition: transform ease .2s;
    svg {
      transform: translateX(5px);
    }
  }  
`
const ArticleDetail = styled.div`
  position: absolute;
  top: 0;
  right:0;
  left: 0;
  bottom:0;
  z-index: 11;
  border-radius: 8px;
  display: ${(props) => props.display};
  /* width: calc(100% - 80px);
  height: 25vw;
  max-height: 270px; */
  background: radial-gradient(rgba(0, 89, 178, 0.1), rgba(0, 89, 178, 0.7));
  .gatsby-image-wrapper {
    height: 50px;
    border-radius: 50px;
  }
`
const HeroSection = styled.div`
  background:#D7E7F5;
  padding-bottom:30px;
  padding-top:90px;
	@media (min-width: 1024px){
		padding-top:120px;		
	}
`
const BadgeList = styled.div`
  flex-wrap: wrap;
  margin:0 -5px;
  display:none;
  ${BreakpointUp.xl`
    display: flex;
  `}
`
const BadgeItem = styled.div`
  padding:0 5px;
`
const BadgeSlider = styled.div`
  ${BreakpointUp.xl`
    display:none;
  `}
`

class Articles extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentCategory: "All",
      articles: this.props.data.allContentfulArticle.edges,
    }
    this.handleCategory = this.handleCategory.bind(this)
  }
  handleCategory(data) {
    this.setState({
      currentCategory: data,
    })
  }
  render() {
    const categories = this.props.data.allContentfulArticleCategory.edges
    const { currentCategory, articles } = this.state
    console.log(currentCategory)
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          },
        },
        {
          breakpoint: 567,
          settings: {
            slidesToShow: 1
          },
        },
      ],
    }
    const { metaTitle, metaDescription, heroSectionTitle } = this.props.data.contentfulArticleListPage
    return (
      <Layout>
        <Seo title={metaTitle} description={metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>         
        <Section xpb="80px" mpb="60px" pt="0" pb="30px">       
          <HeroSection>
            <Container>
              <BreadCrumb><Link to="/"><span>Home</span></Link> Article</BreadCrumb>
              <SectionPageTitle>{heroSectionTitle}</SectionPageTitle>
              <Background right="0" zIndex="0" height="inherit"><IndustryHeroBg /></Background>
              <BadgeList>
                <BadgeItem>
                  <Badge className="badge"
                    key="All Articles"
                    onClick={() => this.handleCategory("All")}
                    bg={currentCategory === "All" ? "#FAB702" : "#FFFFFF"}
                    border={
                      currentCategory === "All" ? "1px solid #C3E3FF" : "none"
                    }
                    color={
                      currentCategory === "All" ? "#FFFFFF" : "rgba(0,0,0,.6)"
                    }
                  >
                    Featured Articles
                    <Arrow
                      display={currentCategory === "All" ? "block" : "none"}
                    />
                  </Badge>
                </BadgeItem>
                {categories.map((item, i) => {
                  return (
                    <BadgeItem>
                      <Badge className="badge"
                        key={i}
                        onClick={() => this.handleCategory(item.node.categoryName)}
                        bg={currentCategory === item.node.categoryName ? "#FAB702" : "#FFFFFF"}
                        border={
                          currentCategory === item.node.categoryName ? "1px solid #C3E3FF" : "none"
                        }
                        color={
                          currentCategory === item.node.categoryName ? "#FFFFFF" : "rgba(0,0,0,.6)"
                        }
                      >
                        {item.node.categoryName}
                        <Arrow
                          display={currentCategory === item.node.categoryName ? "block" : "none"}
                        />
                      </Badge>
                    </BadgeItem>
                  )
                })}
              </BadgeList>
              <BadgeSlider>
                <Slider {...settings}>
                  <BadgeItem>
                    <Badge className="badge"
                      key="Featured Articles"
                      onClick={() => this.handleCategory("All")}
                      bg={currentCategory === "All" ? "#FAB702" : "#FFFFFF"}
                      border={
                        currentCategory === "All"
                          ? "1px solid #C3E3FF"
                          : "none"
                      }
                      color={
                        currentCategory === "All"
                          ? "#FFFFFF"
                          : "rgba(0,0,0,.6)"
                      }
                    >
                      Featured Articles
                    </Badge>
                  </BadgeItem>
                  {categories.map((item, i) => {
                    return (
                      <BadgeItem>
                        <Badge className="badge"
                          key={i}
                          onClick={() => this.handleCategory(item.node.categoryName)}
                          bg={currentCategory === item.node.categoryName ? "#FAB702" : "#FFFFFF"}
                          border={
                            currentCategory === item.node.categoryName
                              ? "1px solid #C3E3FF"
                              : "none"
                          }
                          color={
                            currentCategory === item.node.categoryName
                              ? "#FFFFFF"
                              : "rgba(0,0,0,.6)"
                          }
                        >
                          {item.node.categoryName}
                        </Badge>
                      </BadgeItem>
                    )
                  })}
                </Slider>
              </BadgeSlider>
            </Container>
          </HeroSection>  
          <ArticleList>
            <Container>
              <Grid>
                {articles.map((item, i) => {
                  if (currentCategory === "All") {
                    return(
                      <GridItem display="block">
                        <Link to={"/" + item.node.url}>
                          <ItemHover>
                            {(hoverItem) => (
                              <ArticleCard key={i}>

                                <ArticleHeader>
                                <ArticleDetail
                                  display={hoverItem ? "block" : "none"}
                                >
                                  <WriterWrap ml="20px" mt="20px">
                                    <Avatar>
                                      <GatsbyImage
                                        image={getImage(item.node.authorImage)}
                                        alt="AES"
                                      />
                                    </Avatar>
                                    <Writer color="#fff">
                                      <span className="author">{item.node.authorName}</span>
                                      <span className="role">
                                        {item.node.authorRole}{" "}
                                        <DotIcon fill="#fff" />{" "}
                                        {item.node.postDate}
                                      </span>
                                    </Writer>
                                  </WriterWrap>
                                </ArticleDetail>
                                  <GatsbyImage
                                    image={getImage(item.node.thumbnail)}
                                    alt="AES"
                                  />
                                  {item.node.category?.categoryName && (<ArticleCaption>{item.node.category.categoryName}</ArticleCaption>)}
                                </ArticleHeader>
                                <ArticleBody>
                                  <ArticleTitle>{item.node.title}</ArticleTitle>
                                  <MarkdownContent className="content"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.node.description.childMarkdownRemark
                                          .excerpt,
                                    }}
                                  />
                                  <ArticleLink>Read full Story <ArrowIcon fill="#FAB702" /></ArticleLink>
                                </ArticleBody>
                              </ArticleCard>
                            )}
                          </ItemHover>
                        </Link>
                      </GridItem>
                    )
                  } else {
                    return (
                      <GridItem
                        display={
                          item.node.category.categoryName === currentCategory
                            ? "block"
                            : "none"
                        }
                      >
                        <Link to={"/" + item.node.url}>
                          <ItemHover>
                            {(hoverItem) => (
                              <ArticleCard key={i}>

                                <ArticleHeader>
                                <ArticleDetail
                                  display={hoverItem ? "block" : "none"}
                                >
                                  <WriterWrap ml="20px" mt="20px">
                                    <Avatar>
                                      <GatsbyImage
                                        image={getImage(item.node.authorImage)}
                                        alt="AES"
                                      />
                                    </Avatar>
                                    <Writer color="#fff">
                                      <span className="author">{item.node.authorName}</span>
                                      <span className="role">
                                        {item.node.authorRole}{" "}
                                        <DotIcon fill="#fff" />{" "}
                                        {item.node.postDate}
                                      </span>
                                    </Writer>
                                  </WriterWrap>
                                </ArticleDetail>
                                  <GatsbyImage
                                    image={getImage(item.node.thumbnail)}
                                    alt="AES"
                                  />
                                  <ArticleCaption>{item.node.categoryName}</ArticleCaption>
                                </ArticleHeader>
                                <ArticleBody>
                                  <ArticleTitle>{item.node.title}</ArticleTitle>
                                  <MarkdownContent className="content"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.node.description.childMarkdownRemark
                                          .excerpt,
                                    }}
                                  />
                                  <ArticleLink>Read full Story <ArrowIcon fill="#FAB702" /></ArticleLink>
                                </ArticleBody>
                              </ArticleCard>
                            )}
                          </ItemHover>
                        </Link>
                      </GridItem>
                    )
                  }
                })}
              </Grid>
            </Container>
          </ArticleList>   
        </Section>
        <EmergencyEstimateCTA
          leftData={this.props.data.contentfulAboutPage.aboutEmergencyCta}
          rightData={this.props.data.contentfulAboutPage.aboutEstimateCta}
        />
      </Layout>
    )
  }
}

export default Articles

export const pageQuery = graphql`
  query ArticlePageQuery {
    contentfulArticleListPage {
      metaTitle
      metaDescription
      heroSectionTitle
    }
    allContentfulArticle(sort: { fields: postDate, order: DESC }) {
      edges {
        node {
          url
          title
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 150)
            }
          }
          thumbnail {
            gatsbyImageData(quality: 100)
          }
          authorName
          authorRole
          authorImage {
            gatsbyImageData(quality: 100)
          }
          category {
            categoryName
          }
          postDate(fromNow: true)
        }
      }
    }
    allContentfulArticleCategory(sort: {order: ASC, fields: order}) {
      edges {
        node {
          categoryName
        }
      }
    }
    contentfulAboutPage {
      aboutEstimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      aboutEmergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
  }
`

import React from 'react'

const DotIcon = (props)=>(
	<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
	  <circle id="Ellipse_1" data-name="Ellipse 1" cx="3" cy="3" r="3" fill={props.fill} opacity="0.5"/>
	</svg>
)

DotIcon.defaultProps={
	fill: "#676b79"
}
export default DotIcon